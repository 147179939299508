.sis-table {
    padding: 10px;

    .react-bootstrap-table-pagination {
        margin: 0;
    }

    .sis-search {
        padding-left: 39px !important;
        padding-right: 39px !important;
        position: fixed;
        top: 15px;
        right: 0;
        z-index: 6;
    }

    table {
        .colum-pointer {
            cursor: pointer;
        }
    }

    @media (max-width: 767.98px) {
        .sis-search {
            top: 80px;
            .input-group-prepend{
                position: absolute;
                margin-top: 5px;
            }
            input {
                padding-left: 35px !important;
            }
        }
    }
}

.colum-pointer {
    cursor: pointer;
    .section {
        color: blue;
    }
}