.form-product {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    .table {
        td {
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.separator-product {
    border-left: 1px solid #dde0e2;
    border-right: 1px solid #dde0e2;
}